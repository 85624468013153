<template>
  <div class="list">
    <van-pull-refresh v-model="refreshing">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"   offset="30">
        <div v-for="item in newList" :key="item.id" :title="item" class="article-item">
          <router-link :to="{path:'Detail',query:{id:item.id}}">
            <h2 class="title">{{decodeURIComponent(item.title.replace(/\+/g, "%20"))}}</h2>
            <p class="info">
              <van-tag  color="#c2c2c2" plain >{{ item.channelname }}</van-tag>
              <span class="posttime">{{ item.posttime }}</span>
            </p>
          </router-link>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
export default {
  name: "NewList",
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      lastdataaddtime: '',
      newList: []
    };
  },
  methods: {
    onLoad() {
      this.loaddata();
    },
    loaddata: function() {
      var that = this;
      that.loading = true;
      var params = { top: 10, lastdataaddtime: that.lastdataaddtime };
      that.$api.getLatednew(params).then(res => {
        this.loading = false;
        if (res.data.length < params.top) {
          that.finished = true;
        }
        res.data.forEach(item => {
          that.newList.push(item);
          that.lastdataaddtime = item.addtime;
        });
      });
    }
  }
};
</script>
<style scoped>

.article-item {
  background: #fff;
  border-radius: 0.5rem;
  margin-bottom: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.article-item a .title {
  font-size: 2.4rem;
  color: #000;
  font-weight: normal;
  text-align: left;
  margin: 0;
  line-height: 4rem;
}
.article-item a .info {
  margin: 1rem 0 0 0;
  font-size: 2.4rem;
  color: #333;
  display: flex;
  align-items: center;
}
.posttime{
  font-size: 1.6rem;
  margin-left: 2rem;
  color: #666666;
}
</style>